//首字母大写
const firstToUpper = (str) => {
  if (!str) {
    return "";
  }
  return str.trim().replace(str[0], str[0].toUpperCase());
};

//电话号码转换合适，10位数字转美式电话格式
const phonenumberUS = (number) => {
  let phonenumber = `${number}`;
  if (!phonenumber) {
    return phonenumber;
  }

  let f = phonenumber.slice(0, 3);
  let s = phonenumber.slice(3, 6);
  let t = phonenumber.slice(6);

  return `+1 (${f}) ${s}-${t}`;
};

//格式化时间
const formatDate = (date, number) => {
  if (!date) {
    return " ";
  }
  let newDate = new Date(date);
  if (!number) {
    return `${
      newDate.getMonth() + 1
    } / ${newDate.getDate()} / ${newDate.getFullYear()} `;
  }
  if (number === 5) {
    return `${
      newDate.getMonth() + 1
    } / ${newDate.getDate()} / ${newDate.getFullYear()} ${newDate.getHours()} : ${newDate.getMinutes()} `;
  }
  if (number === 6) {
    return `${
      newDate.getMonth() + 1
    } / ${newDate.getDate()} / ${newDate.getFullYear()} ${newDate.getHours()} : ${newDate.getMinutes()} : ${newDate.getSeconds()}`;
  }
};

const getName = (data) => {
  if (data) {
    let f = data.firstName ? data.firstName : "";
    let m = data.middleName ? data.middleName : "";
    let l = data.lastName ? data.lastName : "";

    return `${f + " "}${m + " "}${l}`;
  } else {
    return "";
  }
};
const getaddressLine = (data) => {
  if (data) {
    let a2 =
      data.addressLine && data.addressLine[1] ? data.addressLine[1] + "," : "";
    let a1 =
      data.addressLine && data.addressLine[1] ? data.addressLine[1] + "," : "";
    let city = data.city ? data.city + " " : "";
    let state = data.state ? data.state + " " : "";
    let country = data.country ? data.country + " " : "";

    return `${a2 + a1 + city + state + country}`;
  } else {
    return "";
  }
};

const maskName = (name) => {
  if (!name) return ""; // Handle empty or undefined names
  // if(REACT_APP_SHOW_FULL) {
  //   return name
  // }
  const firstChar = name.charAt(0).toUpperCase(); // First character to uppercase
  const restOfVisible = name.slice(1, 2); // The second character (unchanged)
  const maskedPart = "***"; // Fixed mask
  return firstChar + restOfVisible + maskedPart;
};

const HidedShowFullDonorIdNew = ({ str, visibleChars }) => {
  if (str.length <= visibleChars) {
    return <span>{str}</span>;
  }

  const visiblePart = str.slice(-visibleChars);
  const maskedPart = '***';
  const hide = maskedPart + visiblePart;

  return <span>{hide}</span>;
};

export { firstToUpper, phonenumberUS, formatDate, getName, getaddressLine, maskName, HidedShowFullDonorIdNew };
