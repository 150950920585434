import React, { useEffect, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Modal, message } from "antd";
import VectorSVG from "@/components/auth/svgicons/VectorSVG";
import uploadUtils from "@/utils/uploadUtils";
/**
 * 手写认证信息
 * prop
 * donorId：用户id
 * onConfirm: ()=>void，通过后回调
 * onCancel: ()=>void, 认证不通过回调
 *
 */
const DonorVerifySignature = React.forwardRef(
  ({ donorId, onConfirm, onCancel }, ref) => {

    const [signatureImg,setSignatureImg]=useState('');
    React.useImperativeHandle(ref, () => {
      return { open: open, close: close ,signatureImg:signatureImg};
    });
    let sigCanva;
    const [show, setShow] = useState(false);
    const [isConfirm, setisConfirm] = React.useState(false);
    const open = () => {
      // 拉起认证窗口
      setShow(true);
    };
    const close = () => {
      // 拉起认证窗口
      setShow(false);
    };
    const cancelClick = () => {
      setShow(false);
      onCancel();
    };
    const handleCloseClick = async () => {
      let sigCopy = null;
      if (sigCanva) {
        sigCopy = sigCanva.toDataURL("image/png");
      }
      if (sigCanva) {
        let aaa = sigCanva.isEmpty();
        if (aaa) {
          message.info("please draw your Signature");
          return;
        }
      }
      setisConfirm(true);
      if (sigCopy) {
        let key = await uploadUtils.uploadBase64(sigCopy);
        setSignatureImg(key);
        setShow(false);
        setisConfirm(false);
        onConfirm();
      }
    };

    return (
      <div>
        {show ? (
          <div>
            <div className="signature " style={{ marginTop: "2rem" }}>
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  className: "sigCanvas  w-100 h-100",
                }}
                ref={(ref) => {
                  sigCanva = ref;
                }}
              />
            </div>
            <div
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <button
                  className="btn_next"
                  style={{
                    background: "#fff",
                    marginRight: "0.5rem",
                    border: "1px solid #888",
                    color: "#152C5B",
                    fontSize: '16px',
                  }}
                  onClick={() => cancelClick()}
                >
                  <VectorSVG /> <text style={{paddingLeft:'0.25rem', fontSize:"0.9rem"}}>Back</text> 
                </button>
              </div>
              <div className="d-flex">
                <button
                  className="btn_next"
                  style={{
                    background: "#fff",
                    marginRight: "0.5rem",
                    border: "1px solid #888",
                    color: "#888",
                  }}
                  onClick={() => sigCanva.clear()}
                >
                  Clear
                </button>
                <button
                  className="btn_next"
                  style={isConfirm ? { opacity: 0.3 } : {}}
                  disabled={isConfirm}
                  onClick={() => handleCloseClick()}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
);

export default DonorVerifySignature;
