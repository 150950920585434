import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HideIcon from "../../../assets/svgicons/donor/HideIcon";
import Recommend from "../../../assets/svgicons/donor/Recommend";
import Tipbox from "../../../../components/Tipbox/Tipbox";
import { phonenumberUS, firstToUpper } from "../../../../components/util/Util";
import './RecommendModal.css'
import md5 from "js-md5";
import { queueQuery, queueQuerys } from "@/pad/components/service/queue/queue";
import { Spin, message } from "antd";


const RecommendModal = ({
  pendshow,
  setpend,
  appCode,
  staffInf,
  dataID,
  onRetake,
  loading,
  setLoading,
  processId
}) => {
  const [showTipbox, setshowTipbox] = useState(false);
  const [isHide, setIsHide] = useState(true)
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false)
  const [ password, setPassword] = useState('')
  const [ email, setEmail] = useState('')
  const [tipBoxValue, setTipBoxValue] = useState("Password input error");
  const checkNext = async () => {   
    setIsSubmit(true)
    if(!password) {
      return false;
    }
    let params = {
      pwd: md5(password),
      email: email,
    }
    if(processId) {
      params.processId = processId
    }
    let passwordConfirm = await queueQuerys("/verify/validity", params);
    if (passwordConfirm.data) {
      setLoading && setLoading(true)
      setTimeout(() => {
      setpend(false);
      navigate(
        `/visitor/donor/create/video`
      );
        setLoading(false)
      },3000);
    } else {
      setshowTipbox(true)
      setTipBoxValue(`Your passwords do not match. Please re-enter the password.`)
      setPassword('')
    }
  };
  const checkRetake = async () => {
    onRetake && onRetake();
  };
  return (
  
    <Modal
      show={pendshow}
      onHide={() => setpend(false)}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ borderRadius: "0.625rem",background:"transparent" }}
    >
       <Spin size="large" tip="Loading..." spinning={loading}>
      <Modal.Body className="p-sm-2 p-md-5 p-lg-5">
        <Tipbox
          show={showTipbox}
          setShow={setshowTipbox}
          value={tipBoxValue}
          state={"failed"}
        />
        <div className="recommend_layout- row g-5-">
          <div className="icon-cus" style={{textAlign: "center"}}><Recommend width="6.169rem" height="6.169rem"/></div>
          <div className="col-12 mt-sm-3 mt-md-3 mt-lg-4 mb-sm-3 mb-md-3 mb-lg-3">
            <p className="recommend_description" style={{fontSize:"1.101rem"}}>Recommend to ask center staff to assist you for the following questionnaire.</p>
          </div>
          <div className="col-12 recommend_form mt-3">
              <div className="w-100 row g-4">
                <div className="col-sm-12 col-md-8 col-lg-8 input_content">
                  <input
                    type={`email`}
                    placeholder=""
                    id="password"
                    value={email}
                    onChange={((e) => setEmail(e.target.value))}
                    className={`form-control input_field recommend_input ${isSubmit && !email ? 'err-border' : ''}`}
                  />
                </div>
              </div>
          </div>
          <div className="col-12 recommend_form mt-3">
            <div className="w-100 row g-4">
              <div className="col-sm-12 col-md-8 col-lg-8 input_content d-flex align-items-center">
                <input
                  type={`${isHide ? "password" : "text"}`}
                  id="password"
                  value={password}
                  placeholder=""
                  onChange={((e) => setPassword(e.target.value))}
                  className={`form-control input_field recommend_input ${isSubmit && !password ? 'err-border' : ''}`}
                />
                <button onClick={() => setIsHide(!isHide)} className="hide_password">
                  <HideIcon width="1rem" height="1rem"/>
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 mt-3 d-flex flex-row align-items-center justify-content-center pt-4 pb-3">
            <div
              className="notes_btn btn_white me-4 d-flex align-items-center justify-content-center"
              onClick={() => {
                checkRetake();
              }}
              style={{
                lineHeight: "0.8rem",
                background: "#fff",
                border: "0.1rem solid rgba(21, 44, 91, 0.10000000149011612)",
                color: "#677389",
                width: "8rem",
                fontSize:"0.881rem",
                height:'2.688rem'
              }}
            >
              Retake
            </div>
            <div
              className="notes_btn btn_next d-flex align-items-center justify-content-center"
              style={{
                width: "8rem",
                fontSize:"0.881rem",
                height:'2.688rem'
              }}
              onClick={() => {
                checkNext();
              }}
            >
              Confirm
            </div>
          </div>
        </div>

      </Modal.Body>
      </Spin>
    </Modal>
   
  );
};
export default RecommendModal;
