import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import "../../styles/pages/visitor/visitor.css";
import { message, Button, Modal, Spin } from "antd";
import { checkDonorExpired, getDonorDetail, visitorLogin } from "../../components/service/visitor/visitor";
import { useDispatch } from "react-redux";
import { setJson } from "../../components/utils/storage";
import { storageKeys } from "../../constants/storage";
import { LOGIN_SUCCESS } from "../../components/state/auth/constants";
import { getDonor } from "@/pad/components/service/donor/donor";
import LogoP from "@/assets/svgicons/LogoP";
import MessageConfirmModal from "@/components/messageModal/MessageConfirmModal";
import urlUtils from "@/utils/urlUtils";
import CountTimecomponent from "@/components/messageModal/CountTimecomponent";
import { formatDateTimeSlasSpaceMMDDYYFullTimeNew } from "../../utils/dateFormatUtils";
const MainPage = () => {

  const dispatch = useDispatch()
  const PROCESS = ["doc", "literacy", "consent"];
  const [loading, setLoading] = useState(false)
  // const inputElement = createRef()
  const inputElement1 = useRef();
  const inputElement2 = useRef();
  const inputElement3 = useRef();
  const inputElement4 = useRef();
  const inputElement5 = useRef();
  const inputElement6 = useRef();
  const inputElement7 = useRef();
  const messageModalRef = useRef("rememberMe");
  const messageModalRef1 = useRef("rememberMe");
  const [datas, setDAta] = useState([
    { k: "Donor ID", v: "", value: "", id: "donorId", c: 1 },
    { k: "Last 4 digits of Identification", v: "", value: "", c: 2 },
    { k: "Birthdate", v: "", value: "", c: 3 },
    { k: "First Name", v: "", value: "", id: "firstName", c: 1 },
    { k: "Last Name", v: "", value: "", id: "lastName", c: 1 },
    { k: "Submit", v: "", value: "", c: 4 },
  ]);

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      fourId: "",
    },
    onSubmit: (values) => { },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [idValue, setIdValue] = useState("");
  const [donorInfomation, setDonorInfomation] = useState({});
  const [donorDataValue, setDonorData] = useState({});
  const [videoState, setVideoState] = useState('Watch Video');
  const [isCreated, setIsCreated] = useState("");
  const [lastCreated, setLastCreated] = useState("");
  const jumpTo = (url) => {
    const webTag = window.open("_black");
    webTag.location.href = url;
  };

  const getProcessDonor = async (DonorData, data) => {
    setLoading(true)
    await getDonorDetail({ donorId: DonorData?.data?.donorId })
      .then((res) => {
        setLoading(false)
        if (res.status === 200) {
          DonorData.data.process = res.data?.process
          DonorData.data.questionnaire = res.data?.questionnaire
          setIsCreated(res && res.data)
        } else {
          DonorData.data.process = 'create'
        }
      }).catch((error) => {
        setLoading(false)
        DonorData.data.process = 'create'
      })

    let questionList = null
    if(DonorData && DonorData.data && DonorData.data.process) {
      questionList = await getDonor("/donors/process", {
        donorId: DonorData?.data?.donorId,
        process: DonorData.data.process,
      });
    }
    if(questionList) {
      questionList = questionList && questionList[questionList.length - 1] ? questionList[questionList.length - 1] : null
      setLastCreated(questionList && questionList.createdAt)
    }

    localStorage.setItem("process", DonorData.data.process)
    
    if (
      DonorData.data.process == "create" ||
      DonorData.data.isCompleted
    ) {
      // 需要先去创建
      setIsModalOpen(false);
      if (DonorData.data.process == "create") {
        messageModalRef1.current.showWarningConfirmsAutoCloseTime(<>
        Donor hasn't completed proof of  documents, please go to the reception for assistance.
        <CountTimecomponent 
            current={0}
            time={`180`}
        />
        </>, () => { }, "", true, () => { 
          window.location.replace("/")
         }, 180000);
      } else if (DonorData.data.isCompleted) {
        //已经全部完成
        jumpTo(
          process.env.REACT_APP_KIOSK_SITE +
          `?siteId=${DonorData.data.siteId}&type=manual&firstName=${data.firstName}&lastName=${data.lastName}&fourDigit=${data.digits4}&donorId=${data.donorId}&birthdate=${data.birthdate}`
        );
      }
    } else {
      //允许

      if (DonorData.data.process == "education" || DonorData.data.process == "consent") {
        setVideoState('Consent Video');
      }
      setDonorInfomation(DonorData);

      let processedStep = DonorData.data.process;

      if (processedStep == 'literacy') {
        // if pass direct to watch video
        const { questionnaire } = DonorData.data
        if (questionnaire && questionnaire[0]) {
          if (questionnaire[0]?.result == "pass") {
            processedStep = 'education';
          } else {
            if(questionList.confirmedBy) {
              processedStep = 'education';
            }
          }
        }
      }

      let stepData = 0;
      if (processedStep === "doc" || processedStep === "literacy") {
        stepData = 1;
      } else if (processedStep === "consent" || processedStep === "education") {
        // watch video
        stepData = 2;
      }
      setstep(stepData);

      setIsModalOpen(true);
    }
  }

  const DeferralCategory = (messageCateory, messageDate) => {
    let type = messageCateory?.toLowerCase()
    switch (type) {
      case 'temporary':
        return <div>
          Sorry, your account has deferral and next eligible donation date should be {formatDateTimeSlasSpaceMMDDYYFullTimeNew(messageDate)}. If you have any question, please go to the reception to ask center staff for assistance.
        </div>;
      case 'adverse':
        return <div>
          Sorry, your account has deferral and next eligible donation date should be {formatDateTimeSlasSpaceMMDDYYFullTimeNew(messageDate)}. If you have any question, please go to the reception to ask center staff for assistance.
        </div>;
      case 'indefinite':
        return <div>
          Sorry, your account has one indefinite deferral. If you have any question, please go to the reception to ask center staff for assistance.
        </div>;
      case 'permanent':
        return <div>
          Sorry, your account has one permanent deferral. If you have any question, please go to the reception to ask center staff for assistance.
        </div>;
      default:
        return <div>Unknown Deferral Category</div>;
    }
  };

  const checkDonorProcss = async (response, data) => {
    if (response.status === 200) {
      localStorage.setItem("user_token", response.data.token);
      localStorage.setItem(storageKeys.TOKEN, response.data.token)
      localStorage.setItem(storageKeys.SITE_TIMEZONE, response.data && response.data.timeZone)
      localStorage.setItem(storageKeys.SITE, response.data.siteId)
      
      setJson(storageKeys.USER, response.data)
      let dataNew = {
        visitor_user: response.data,
        visitor_token: response.data.token,
      }

      dispatch({
        type: LOGIN_SUCCESS,
        payload: dataNew
      })

      let checkDeferral = null
      setLoading(true)
      await checkDonorExpired({ donorId: response.data.donorId })
        .then((res) => {
          setLoading(false)
          checkDeferral = res
        }).catch((error) => { setLoading(false) });

      let checkDeferrals = checkDeferral ? checkDeferral.data : null
        if (checkDeferral.data && checkDeferral.data?.deferralCycle && checkDeferral.data?.deferralCycle?.toLowerCase()) {
        let messageDef = checkDeferrals?.deferrals[0]
        if(messageDef.deferralCategory){
          messageModalRef1.current.showWarningConfirmsAutoCloseTime(
            <>
              <div
                style={{
                  textAlign: "left",
                  fontWeight: "500",
                  fontSize: "0.99rem"
                }}
              >{DeferralCategory(messageDef.deferralCategory?.toLowerCase()
                , checkDeferrals?.nextEligibleData) || 'Sorry, donor is not found. Please check and input the correct donor information or go to the reception for assistance.'}
              </div>
              <CountTimecomponent
                current={0}
                time={`180`}
              />
              </>
            , () => { }, "", true, () => { 
              window.location.replace("/")
             }, 180000);
          return;
        }
      }

      await getProcessDonor(response, data);
    } else {
      response.message = response.message === "Donor not found" ? `Sorry, donor is not found. Please check and input the correct donor information or go to the reception for assistance` : response.message
      response.message = response.message === "New Donor creation is completed." ? `New donor creation process has been completed. You can go to Kiosk for checkin or go to the reception for more assistance.` : response.message
      response.message = response.message === "Please note that you have deferrals!" ? `New donor creation process has been completed. You can go to Kiosk for checkin or go to the reception for more assistance.` : response.message
      messageModalRef1.current.showWarningConfirmsAutoCloseTime(<>
      {response.message || 'Sorry, donor is not found. Please check and input the correct donor information or go to the reception for assistance.'}
        <CountTimecomponent
          current={0}
          time={`180`}
        />
      </>, () => { 
      }, "", true, () => { 
        window.location.replace("/")
       }, 180000 );
    }
  }


  const handleStart = async () => {
    let data = {
      donorId: document.getElementById("donorId").value,
      digits4: `${fourCode.first}${fourCode.second}${fourCode.third}${fourCode.fouth}`,
      firstName: document.getElementById("firstName").value,
      lastName: document.getElementById("lastName").value,
      birthdate: `${birth.year.substring(0, 4)}-${birth.month.substring(
        0,
        2
      )}-${birth.day.substring(0, 2)}`,
    };

    if (!data.donorId ||
      !data.digits4 ||
      !data.firstName ||
      !data.lastName ||
      !data.birthdate
    ) {
      messageModalRef1.current.showWarningConfirmsAutoClose(`Please input information!`, () => { }, "", true);
      return false
    }
    data.firstName = data.firstName
    data.lastName = data.lastName
    try {
      setLoading(true)
      setDonorData(data);
      await visitorLogin(data).then(async (DonorData) => {
        setLoading(false)
        checkDonorProcss(DonorData, data)
      });
    } catch (error) {
      console.log(`error: `, error)
      setLoading(false)
      messageModalRef.current?.showError(
        <p style={{ textAlign: 'center' }} >{`Server Error!`}</p>,
        () => { },
        ""
      );
    }

  };

  const startAnswer = () => {
    const shouldShowProofOfDocuments = isCreated && isCreated.status === "pre" && urlUtils.isEightWeeksElapsed(isCreated && isCreated.createdAt) && urlUtils.isEightWeeksElapsed(lastCreated && lastCreated);
    if(shouldShowProofOfDocuments && shouldShowProofOfDocuments){
      messageModalRef1.current.showWarningConfirmsAutoCloseTime(<>
        {'Sorry, Your previous process was stopped for over 8 weeks. Information or go to the reception for assistance.'}
          <CountTimecomponent
            current={0}
            time={`180`}
          />
        </>, () => { 
        }, "", true, () => { 
          window.location.replace("/")
         }, 180000 );
      return false
    }
    localStorage.setItem("visit_step", step);

    if (step === 2) {
      localStorage.setItem("reset_content_step", true);
    }
    let url = `/visitor/donor/create/check`
    if (step === 0 || step === 1) {
      url = `/visitor/donor/create/check`
    }
    if (step === 2) {
      url = `/visitor/donor/create/video`
    }
    /*
    temporary not needed
    if(step === 2) {
      url = `/visitor/donor/create/checklist`
    }*/
    navigate(`${url}?app_unique_code=`, {
      replace: true,
      state: {
        data: "create-new-donor",
        donorid: donorInfomation.data.donorId,
        step: step,
        donorInfo: donorInfomation.data,
      },
    });
    message.success("Successfully!");
  };

  const [birth, setbirth] = useState({
    year: "",
    month: "",
    day: "",
  });

  const [fourCode, setFourCode] = useState({
    first: "",
    second: "",
    third: "",
    fouth: "",
  });

  const [step, setstep] = useState(0);

  return (
    <div
      className="w-100 d-flex  justify-content-center align-items-center"
      style={{
        backgroundColor: "rgba(239,240,244,1)",
        // height: "calc(100vh - 0.375rem)",
        height: "100%",
      }}
    >
    <Spin size="large" Tips="Loading..." spinning={loading}>
      <MessageConfirmModal textCentered ref={messageModalRef1} />
      <MessageConfirmModal textCentered widthBtn={`auto`} noTitle={`No, Keep Stay`} yesTitle={`Yes, Leave without Saving`} ref={messageModalRef} />
      <div 
      className="div-login"
      style={{height: "100%", overflow: "auto"}}
      >
      <div
        className="d-flex justify-content-center align-items-center flex-column  w-div-cus overflow-auto pt-sm-2 pt-md-4 pt-lg-4 pb-sm-2 pb-md-4 pb-lg-4"
        style={{height: "100%", minHeight: "615px"}}
       
      >
          <div className="w-70 h-100- d-flex flex-wrap align-content-between pb-3"
          style={{margin: "auto", height: "600px", maxWidth: "700px"}}
          >
            <div className="w-100">

              <Row className="pt-2 row ">
                <Col sm={12} md={12} lg={12}>
                  <div className="d-flex justify-content-center w-100 align-items-center">
                    <div
                      className="logo-cus"
                    >
                      <LogoP
                        width={`100%`}
                        height={`100%`}
                      />
                    </div>
                    <div style={{paddingLeft: "1rem"}}>
                      <h1 className="h1-cus">PAcS FOR VISITOR</h1>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="pt-sm-2 pt-md-5 pt-lg-5 pb-3 pb-md-3 pd-lg-3 row">
                <Col sm={12} md={12} lg={12}>
                  <h2 className="text-cus-2">
                    Please input below information to find your donor account.
                  </h2>
                </Col>
              </Row>

              {
                datas.map((item) => (
                  <>

                    {item.c === 1 && (
                      <Row className="pt-3 row ">
                      <Col sm={12} md={5} lg={5} className="d-flex align-items-center">
                        <div className='labelSize16Weight600 ml-cus pb-1'>{item.k} <span style={{ color: '#FC1921' }}>*</span> </div>
                      </Col>
                      <Col sm={12} md={7} lg={7}>
                      <input
                          type="text"
                          autoComplete="off"
                          value={item.value}
                          onChange={(e) => {
                            item.value = e.target.value
                            setDAta([...datas])
                          }}
                          id={`${item.id}`}
                          style={{paddingLeft:"0.9rem"}}
                          className="input-cus w-100"
                        ></input>
                      </Col>
                    </Row>
                    )}

                    {
                      item.c === 2 && (<Row className="pt-3 row ">
                        <Col sm={12} md={5} lg={5} className="d-flex align-items-center">
                          <div className='labelSize16Weight600 ml-cus pb-1'>{`Last 4 digits of Identification`} <span style={{ color: '#FC1921' }}>*</span> </div>
                        </Col>
                        <Col sm={12} md={7} lg={7} className="d-flex ">
                          <input
                            className="index-row-input-number"
                            autoComplete="off"
                            style={styles.inputStyle}
                            // id="fourId"
                            ref={inputElement1}
                            // value={idValue.substring(0, 1)}
                            value={fourCode.first.replace(/[/W]/g, "").substring(0, 1)}
                            // value={formik.values.fourId}
                            maxLength={1}
                            // onChange={formik.handleChange}
                            onChange={(e) => {
                              setFourCode((i) => {
                                let code = { ...i };
                                code.first = e.target.value;
                                // if (code.first) {
                                //   inputElement.current.focus()
                                // }
                                return code;
                              });
                              if (e.target.value) {
                                inputElement2.current.focus();
                              }
                            }}
                            onKeyUp={(e) => {
                              if (e.keyCode == 8) {
                              } else {
                                if (fourCode.first.length > 0) {
                                  inputElement2.current.focus();
                                }
                              }
                            }}
                          // onChange={changeIdValue}
                          />
                          <input
                            className="index-row-input-number"
                            autoComplete="off"
                            style={styles.inputStyle}
                            // id="fourId"
                            ref={inputElement2}
                            // ref={ref=>{
                            //   inputElement=ref}}
                            // ref={inputElement}
                            // value={idValue.substring(0, 1)}
                            value={fourCode.second.replace(/[/W]/g, "").substring(0, 1)}
                            // value={formik.values.fourId}
                            maxLength={1}
                            onChange={(e) => {
                              setFourCode((i) => {
                                let code = { ...i };
                                code.second = e.target.value;
                                // if (code.second) {
                                //   document.getElementById("inputElement1").focus()
                                //   // inputElement1.current.focus()
                                // }
                                return code;
                              });
                              if (e.target.value) {
                                inputElement3.current.focus();
                              }
                            }}
                            onKeyUp={(e) => {
                              if (e.keyCode == 8) {
                                inputElement1.current.focus();
                              } else {
                                if (fourCode.second.length > 0) {
                                  inputElement3.current.focus();
                                }
                              }
                            }}

                          // onChange={formik.handleChange}
                          />
                          <input
                            className="index-row-input-number"
                            autoComplete="off"
                            style={styles.inputStyle}
                            ref={inputElement3}
                            // ref={inputElement1}
                            // id="fourId"
                            // id="inputElement1"
                            // value={idValue.substring(0, 1)}
                            value={fourCode.third.replace(/[/W]/g, "").substring(0, 1)}
                            // value={formik.values.fourId}
                            maxLength={1}
                            // onChange={formik.handleChange}
                            onChange={(e) => {
                              setFourCode((i) => {
                                // inputElement2.current.focus()
                                let code = { ...i };
                                code.third = e.target.value;
                                return code;
                              });
                              if (e.target.value) {
                                inputElement4.current.focus();
                              }
                            }}
                            onKeyUp={(e) => {
                              if (e.keyCode == 8) {
                                inputElement2.current.focus();
                              } else {
                                if (fourCode.third.length > 0) {
                                  inputElement4.current.focus();
                                }
                              }
                            }}
                          // onChange={changeIdValue}
                          />
                          <input
                            className="index-row-input-number"
                            autoComplete="off"
                            style={styles.inputStyle}
                            ref={inputElement4}
                            // value={idValue.substring(3, 4)}
                            // id="fourId"
                            // value={idValue.substring(0, 1)}
                            value={fourCode.fouth.replace(/[/W]/g, "").substring(0, 1)}
                            // value={formik.values.fourId}
                            maxLength={1}
                            // onChange={formik.handleChange}
                            onChange={(e) => {
                              setFourCode((i) => {
                                let code = { ...i };
                                code.fouth = e.target.value;
                                return code;
                              });
                            }}
                            onKeyUp={(e) => {
                              if (e.keyCode == 8) {
                                inputElement3.current.focus();
                              }
                            }}
                          // onChange={changeIdValue}
                          />
                        </Col>
                      </Row>
                      )
                    }

                    {
                      item.c === 3 && (
                        <Row className="pt-3 row ">
                          <Col sm={12} md={5} lg={5} className="d-flex align-items-center">
                            <div className='labelSize16Weight600 ml-cus pb-1'>{`Birthdate`} <span style={{ color: '#FC1921' }}>*</span> </div>
                          </Col>
                          <Col sm={12} md={7} lg={7}>
                            <div className=" d-flex flex-row align-items-center">
                              <div className="verify_birth_box">
                                <Form.Control
                                  className="w-100 h-100"
                                  autoComplete="off"
                                  ref={inputElement5}
                                  placeholder="MM"
                                  id="inputDate"
                                  aria-describedby="passwordHelpBlock"
                                  value={birth.month.replace(/[^\d]/, "").substring(0, 2)}
                                  onChange={(e) => {
                                    setbirth((i) => {
                                      let copy = { ...i };
                                      copy.month = e.target.value;
                                      return copy;
                                    });
                                    if (e.target.value?.length >= 2) {
                                      inputElement6.current.focus();
                                    }
                                  }}
                                  onKeyUp={(e) => {
                                    if (e.keyCode == 8) {
                                    } else {
                                      if (birth.month.length > 1) {
                                        inputElement6.current.focus();
                                      }
                                    }
                                  }}
                                />
                              </div>
                              <span
                                style={{
                                  paddingLeft: "0.63rem",
                                  paddingRight: "0.63rem",
                                }}
                              >
                                /
                              </span>
                              <div className="verify_birth_box">
                                <Form.Control
                                  className="w-100 h-100"
                                  autoComplete="off"
                                  ref={inputElement6}
                                  placeholder="DD"
                                  id="inputDate"
                                  aria-describedby="passwordHelpBlock"
                                  value={birth.day.replace(/[^\d]/, "").substring(0, 2)}
                                  onChange={(e) => {
                                    setbirth((i) => {
                                      let copy = { ...i };
                                      copy.day = e.target.value;
                                      return copy;
                                    });
                                    if (e.target.value?.length >= 2) {
                                      inputElement7.current.focus();
                                    }
                                  }}
                                  onKeyUp={(e) => {
                                    if (e.keyCode == 8 && !e.target.value) {
                                      inputElement5.current.focus();
                                    } else {
                                      if (birth.day.length > 1) {
                                        inputElement7.current.focus();
                                      }
                                    }
                                  }}
                                />
                              </div>
                              <span
                                style={{
                                  paddingLeft: "0.63rem",
                                  paddingRight: "0.63rem",
                                }}
                              >
                                /
                              </span>
                              <div className="verify_birth_box">
                                <Form.Control
                                  className="w-100 h-100"
                                  autoComplete="off"
                                  placeholder="YYYY"
                                  ref={inputElement7}
                                  id="inputDate"
                                  aria-describedby="passwordHelpBlock"
                                  value={birth.year.replace(/[^\d]/, "").substring(0, 4)}
                                  onChange={(e) => {
                                    setbirth((i) => {
                                      let copy = { ...i };
                                      copy.year = e.target.value;
                                      return copy;
                                    });
                                  }}
                                  onKeyUp={(e) => {
                                    if (e.keyCode == 8 && !e.target.value) {
                                      inputElement6.current.focus();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )
                    }

                  </>
                ))
              }

            </div>

            <div className="w-100">
              <Row>
                <Col sm={12} md={12} lg={12}>
                  <div className="w-100 d-flex justify-content-center align-items-center">
                    <Button
                      type="primary"
                      className="btn-submit"
                      onClick={handleStart}
                    >
                      Submit
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>

          </div>
      </div>
      </div>

      {isModalOpen ? <Modal
        centered={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          style={{
            width: "90%",
            height: "10rem",
            margin: "0 auto",
            fontSize: "1rem",
            fontWeight: "500",
          }}
        >
          <h5 style={{ fontWeight: "bold" }}>Welcome to PAcS</h5>
          <p>Current Step :</p>
          <div
            style={{
              width: "100%",
              height: "6rem,",
              backgroundColor: "#f1f1f1",
              display: "flex",
              paddingLeft: "2rem",
              alignItems: "center",
              paddingTop: "0.8rem",
              paddingBottom: "0.8rem",
              // padding:"2rem"
            }}
          >
            <div>
              <button
                style={{
                  width: "2rem",
                  height: "2rem",
                  backgroundColor: "#ffffff",
                  borderRadius: "1rem",
                  border: "none",
                  color: "blue",
                  fontWeight:600
                }}
              >
                {donorInfomation &&
                  step}
              </button>
              <span>
                {" "}
                {donorInfomation &&
                  step == 1 &&
                  "Literacy Check"}
                {donorInfomation &&
                  step == 2 &&
                  videoState}
              </span>
            </div>
            <div
              style={{ marginLeft: "3.5rem", color: "f1f1f1", opacity: "0.4" }}
            >
              <span>------</span>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            style={{
              width: "65%",
              borderRadius: "0.5rem",
              backgroundColor: "#0075ff",
              fontWeight:600
            }}
            onClick={startAnswer}
          >
            Start
          </Button>
        </div>
      </Modal> : ''}
    </Spin>
    </div>
  );
};
const styles = {
  inputStyle: {
    textAlign: "center",
  },
};
export default MainPage;
//  export default connect(
//   ({ stepStateReducer: donorInfoState }) => {
//     return {
//       donorStep: donorInfoState.donorStep
//     }
//   }
// )(MainPage)
