import React, { useEffect, useState } from "react";

const CountTimecomponent = ({ time, onCount, current, color }) => {
  const [countTime, setTimerCount] = useState(time);
  useEffect(() => {
    setTimerCount(time);
    if (time == 0) {
      onCount && onCount();
    }
  }, [time, current]);
  useEffect(() => {
    const timer = setInterval(() => {
      if (countTime > 0) {
        setTimerCount((i) => i - 1);
      }
    }, 1000);
    if (countTime === 0) {
      onCount && onCount();
    }
    return () => {
      clearInterval(timer);
    };
  }, [countTime]);

  return <p style={styles.count_font} className={`${color} count-time`}>{countTime}s</p>;
};

const styles = {
  count_font: {
    color: "rgb(0, 117, 255)",
    fontFamily: "Montserrat",
    fontSize: "1rem",
  },
};
export default CountTimecomponent;
